<template>
  <div class="role">
    <div class="globle_border" v-loading="loading">
      <div class="search">
        <el-input v-model="search" placeholder="请输入角色名称" suffix-icon="el-icon-search" @change="onSearch" clearable></el-input>
        <el-button type="primary" @click="handleAdd">创建角色</el-button>
      </div>

      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%" max-height="540">
          <el-table-column show-overflow-tooltip min-width="120" prop="roleSort" label="显示顺序"></el-table-column>
          <el-table-column show-overflow-tooltip min-width="120" prop="roleName" label="角色名称"></el-table-column>
          <el-table-column show-overflow-tooltip min-width="120" prop="" label="状态">
            <template slot-scope="scope">
              <el-switch v-model="scope.row.switch" active-color="#069BBC" inactive-color="#E2E2E2" @change="changeStatus(scope.row)"></el-switch>
            </template>
          </el-table-column>
          <el-table-column label="操作" header-align="center" align="center" width="280" fixed="right">
            <template slot-scope="scope">
              <el-button class="btn" type="primary" size="small" plain @click="handleAssociatedUsers(scope.$index, scope.row)">关联用户</el-button>
              <el-button class="btn" type="" size="small" plain @click="handleEdit(scope.$index, scope.row)">修改</el-button>
              <el-button class="btn" type="danger" size="small" plain @click="handleDelete(scope.$index, scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr" layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount"></el-pagination>

    <el-dialog :title="isAdd ? '创建角色' : '修改'" :visible.sync="dialogVisible" :before-close="onCancel">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="160px" class="demo-ruleForm">
        <el-row>
          <el-col :span="12">
            <el-form-item label="角色名称" prop="roleName">
              <el-input v-model="ruleForm.roleName" placeholder="请输入角色名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="显示顺序" prop="roleSort">
              <el-input v-model="ruleForm.roleSort" placeholder="请输入显示顺序"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="PC端菜单权限" prop="menuIds">
              <el-checkbox v-model="checkedAll1" @change="handleSelectAll1">全选/全不选</el-checkbox>
              <div class="tree" style="min-height: 300px">
                <el-tree ref="tree1" :data="menuList1" show-checkbox :check-strictly="checkStrictly" :node-key="nodeKey" :props="defaultProps" :default-checked-keys="defaultCheckedKeys1" @check-change="handleCheckChange1"></el-tree>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="移动端菜单权限" prop="h5MenuIds">
              <el-checkbox v-model="checkedAll2" @change="handleSelectAll2">全选/全不选</el-checkbox>
              <div class="tree" style="min-height: 300px">
                <el-tree ref="tree2" :data="menuList2" show-checkbox :check-strictly="checkStrictly" :node-key="nodeKey" :props="defaultProps" :default-checked-keys="defaultCheckedKeys2" @check-change="handleCheckChange2"></el-tree>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <span slot="footer">
        <el-button @click="onCancel">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')" :loading="submitLoding">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      submitLoding: false,
      search: "",
      tableData: [],
      menuList1: [],
      menuList2: [],
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
      dialogVisible: false,
      isAdd: true,
      roleStatus: "1",
      ruleForm: {
        roleName: "",
        roleSort: "",
        menuIds: [],
        h5MenuIds: [],
      },
      rules: {
        roleName: [{ required: true, message: "请输入角色名称", trigger: "submit" }],
        roleSort: [{ required: true, message: "请输入显示顺序", trigger: "submit" }],
        // menuIds: [{ required: true, message: "请选择菜单权限", trigger: "submit" },],
        // h5MenuIds: [{ required: true, message: "请选择菜单权限", trigger: "submit" },],
      },
      nodeKey: "menuId",
      checkedAll1: false, // 全选
      checkedAll2: false, // 全选
      checkStrictly: false, // false为开启父子联动
      defaultCheckedKeys1: [], // 设置默认选中的节点
      defaultCheckedKeys2: [], // 设置默认选中的节点
      defaultProps: {
        children: "children",
        label: "menuName",
      },
    }
  },
  created() {
    this.getSysRoleList()
    this.getSysMenuList1()
    this.getSysMenuList2()
  },
  methods: {
    // 角色列表
    getSysRoleList() {
      this.loading = true
      this.$axios
        .get(this.$api.getSysRoleList, {
          params: {
            roleName: this.search,
            page: this.currentPage,
            pageSize: this.pageSize,
          },
        })
        .then(res => {
          let list = res.data.result.list.map(e => {
            return {
              ...e,
              switch: e.status == "1" ? true : false,
            }
          })
          this.tableData = list
          this.totalItemsCount = res.data.result.totalCount
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    // PC菜单列表
    getSysMenuList1() {
      this.$axios
        .get(this.$api.getSysMenuList, {
          params: {
            menuList: "0", // 0：PC端菜单,1：移动端菜单
          },
        })
        .then(res => {
          this.menuList1 = res.data.result
        })
    },
    // 移动端菜单列表
    getSysMenuList2() {
      this.$axios
        .get(this.$api.getSysMenuList, {
          params: {
            menuList: "1", // 0：PC端菜单,1：移动端菜单
          },
        })
        .then(res => {
          this.menuList2 = res.data.result
        })
    },
    // 搜索
    onSearch() {
      this.currentPage = 1
      this.getSysRoleList()
    },
    // 新增
    handleAdd() {
      this.isAdd = true
      this.ruleForm = {}
      this.defaultCheckedKeys1 = []
      this.defaultCheckedKeys2 = []
      this.dialogVisible = true
    },
    // 关联用户
    handleAssociatedUsers(index, row) {
      this.$router.push({ path: "/system/detail/associatedUsers", query: { id: row.roleId } })
    },
    // 修改
    handleEdit(index, row) {
      this.isAdd = false
      this.loading = true
      this.$axios
        .get(`${this.$api.getSysRole}/${row.roleId}`)
        .then(res => {
          if (res.data.code == 100) {
            this.ruleForm = res.data.result
            this.defaultCheckedKeys1 = res.data.result.menuIds
            this.defaultCheckedKeys2 = res.data.result.h5MenuIds
            // 匹配菜单数组中父节点 menuId ，父节点 menuId 如果存在 this.defaultCheckedKeys 则删除对应元素，实现父子联动回显
            // this.defaultCheckedKeys1 = this.defaultCheckedKeys1.filter(item1 => { return !this.menuList1.some(item2 => item2.menuId == item1) })
            // this.defaultCheckedKeys2 = this.defaultCheckedKeys2.filter(item1 => { return !this.menuList2.some(item2 => item2.menuId == item1) })
            this.dialogVisible = true
          } else {
            this.$message.error(res.data.desc)
          }
          this.loading = false
        })
        .catch(() => {
          this.dialogVisible = false
          this.loading = false
        })
    },
    // 删除
    handleDelete(index, row) {
      this.$confirm("此操作将永久删除该角色, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          this.$axios
            .put(this.$api.deleteSysRole, {
              roleId: row.roleId,
              status: "9",
            })
            .then(res => {
              if (res.data.code == 100) {
                this.$message.success(res.data.desc)
              }
              this.getSysRoleList()
            })
            .catch(() => {})
        })
        .catch(() => {})
    },
    // 切换每页显示的条数
    handleSizeChange(e) {
      this.pageSize = e
      this.getSysRoleList()
    },
    // 换页
    handleCurrentChange(e) {
      this.currentPage = e
      this.getSysRoleList()
    },
    // 关闭对话框
    onCancel() {
      this.submitLoding = false
      this.dialogVisible = false
      this.resetForm()
    },
    // 切换角色状态
    changeStatus(row) {
      const originalSwitch = row.switch
      this.$confirm(`该角色即将${row.switch ? "启用" : "停用"}，是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          this.$axios
            .put(this.$api.deleteSysRole, {
              roleId: row.roleId,
              status: row.switch ? "1" : "3",
            })
            .then(() => {
              this.$set(row, "switch", originalSwitch)
              this.$message.success("操作成功")
            })
            .catch(() => {
              this.$set(row, "switch", !originalSwitch)
              this.$message.error("操作失败")
            })
        })
        .catch(() => {
          this.$set(row, "switch", !originalSwitch)
        })
    },
    // 全选/全不选1
    handleSelectAll1() {
      if (this.checkedAll1) {
        this.$refs.tree1?.setCheckedKeys(this.getAllKeys1())
      } else {
        this.$refs.tree1?.setCheckedKeys([])
      }
    },
    // 全选/全不选2
    handleSelectAll2() {
      if (this.checkedAll2) {
        this.$refs.tree2?.setCheckedKeys(this.getAllKeys2())
      } else {
        this.$refs.tree2?.setCheckedKeys([])
      }
    },
    // 检查选中状态
    checkAllRoles1() {
      const allKeys = this.getAllKeys1()
      const checkedKeys = this.$refs.tree1?.getCheckedKeys()
      return allKeys.length === checkedKeys.length
    },
    // 检查选中状态
    checkAllRoles2() {
      const allKeys = this.getAllKeys2()
      const checkedKeys = this.$refs.tree2?.getCheckedKeys()
      return allKeys.length === checkedKeys.length
    },
    // 获取所有节点的 ID
    getAllKeys1() {
      const keys = []
      const traverse = nodes => {
        nodes.forEach(node => {
          keys.push(node[this.nodeKey])
          if (node.children && node.children.length > 0) {
            traverse(node.children, node[this.nodeKey])
          }
        })
      }
      traverse(this.menuList1, null)
      return keys
    },
    // 获取所有节点的 ID
    getAllKeys2() {
      const keys = []
      const traverse = nodes => {
        nodes.forEach(node => {
          keys.push(node[this.nodeKey])
          if (node.children && node.children.length > 0) {
            traverse(node.children, node[this.nodeKey])
          }
        })
      }
      traverse(this.menuList2, null)
      return keys
    },
    // 菜单勾选状态改变1
    handleCheckChange1(node, checked, indeterminate) {
      if (indeterminate) return
      if (checked) {
        this.checkedAll1 = this.checkAllRoles1(node)
      } else {
        this.checkedAll1 = false
      }
    },
    // 菜单勾选状态改变2
    handleCheckChange2(node, checked, indeterminate) {
      if (indeterminate) return
      if (checked) {
        this.checkedAll2 = this.checkAllRoles2(node)
      } else {
        this.checkedAll2 = false
      }
    },
    // 提交表单
    submitForm(formName) {
      this.submitLoding = true
      // 提交表单前，收集所有选中节点及其部分选中父节点的menuId
      let selectNode1 = this.$refs.tree1?.getCheckedKeys()
      let selectNode2 = this.$refs.tree2?.getCheckedKeys()
      let selectNodeParent1 = this.$refs.tree1?.getHalfCheckedKeys()
      let selectNodeParent2 = this.$refs.tree2?.getHalfCheckedKeys()
      // 合并父节点menuId和子节点menuId
      this.ruleForm.menuIds = selectNode1.concat(selectNodeParent1)
      this.ruleForm.h5MenuIds = selectNode2.concat(selectNodeParent2)
      let params = Object.assign({}, this.ruleForm)
      params.menuIdList = {
        0: this.ruleForm.menuIds,
        1: this.ruleForm.h5MenuIds,
      }
      let isEditMenu = params.menuIdList[0].length == this.defaultCheckedKeys1?.length ? false : true
      let isEditMyRoleId = this.$store.state.userInfo.roleIds.includes(params.roleId)
      this.$refs[formName].validate(valid => {
        if (valid) {
          let api = ""
          let modes = ""
          if (this.isAdd) {
            api = this.$api.addSysRole
            modes = "post"
          } else {
            api = this.$api.updateSysRole
            modes = "put"
          }
          this.$axios[modes](api, params)
            .then(res => {
              if (res.data.code == 100) {
                this.$message.success("修改成功")
                this.getSysRoleList()
                this.submitLoding = false
                this.dialogVisible = false
                this.resetForm()
                setTimeout(() => {
                  if (!this.isAdd && isEditMenu && isEditMyRoleId) {
                    // 如果修改改动了菜单，则强制退出登录
                    localStorage.removeItem("lastDetailPath")
                    localStorage.clear()
                    this.$store.dispatch("logout")
                    this.$router.replace("/login")
                  }
                }, 500)
              }
            })
            .catch(err => {
              this.$message.error(err.data.desc)
              this.submitLoding = false
            })
        } else {
          this.submitLoding = false
          this.$message.error("提交失败")
          return false
        }
      })
    },
    // 重置校验
    resetForm() {
      this.ruleForm = {}
      this.defaultCheckedKeys1 = []
      this.defaultCheckedKeys2 = []
      this.$refs.tree1?.setCheckedNodes([])
      this.$refs.tree2?.setCheckedNodes([])
      this.checkedAll1 = false
      this.checkedAll2 = false
      this.$refs.ruleForm.resetFields()
    },
  },
}
</script>

<style scoped lang="scss">
.role {
  .search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 44px;
    margin-bottom: 20px;

    .el-input {
      width: 300px;
    }

    .el-button {
      margin-left: 20px;
    }
  }

  .tree {
    width: 250px;
    margin-top: 10px;
    border: 1px solid #e2e2e2;
    border-radius: 6px;
    padding: 20px;
    box-sizing: border-box;

    .el-tree {
      background-color: transparent;
    }
  }

  /deep/ .el-radio-button:first-child .el-radio-button__inner {
    border-radius: 6px 0 0 6px;
  }

  /deep/ .el-radio-button:last-child .el-radio-button__inner {
    border-radius: 0 6px 6px 0;
  }
}
</style>
